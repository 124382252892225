import React, { useCallback, useEffect, useState } from 'react';
import classNames from 'classnames';
import { useQuery, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';

import Heading from '$ui/heading';
import Loader from '$ui/loader';
import Button from '$ui/button';

import UserLayout from '$components/layout/user';

import { useUser } from '$hooks/auth';

import loadRazorpay from '$utils/loadRazorpay';
import {
  getComplaintById,
  getRazorpayOrderForComplaint
} from '$utils/queryFunctions';

import logoImg from '$images/logo.png';

import PendingIcon from '$assets/pending.svg';
import AcceptIcon from '$assets/accept.svg';
import PaidIcon from '$assets/paid.svg';
import UnpaidIcon from '$assets/unpaid.svg';
import ResolvedIcon from '$assets/tick.svg';

const statusTypes = ['PENDING', 'ACCEPTED', 'PAID', 'RESOLVED'];

const icons = [
  <PendingIcon className='h-5 fill-slate-500 phone:h-4' />,
  <AcceptIcon className='h-5 fill-slate-500 phone:h-4' />,
  <UnpaidIcon className='h-5 fill-slate-500 phone:h-4' />,
  <PaidIcon className='h-5 fill-slate-500 phone:h-4' />,
  <ResolvedIcon className='h-5 fill-slate-500 phone:h-4' />
];

const ComplaintDetailsPage = ({ params }) => {
  const [isRazorpayLoading, setIsRazorpayLoading] = useState(true);
  const queryClient = useQueryClient();
  const { user } = useUser();
  const { data: complaint, isLoading } = useQuery(
    ['complaint', params.id],
    getComplaintById
  );

  const handlePayment = useCallback(async () => {
    if (!window.Razorpay || !user)
      return toast.error('Could not process payments at the moment');

    try {
      const razorpayOrder = await getRazorpayOrderForComplaint(params.id);

      const options = {
        key: process.env.RAZORPAY_KEY_ID,
        amount: razorpayOrder.amount,
        currency: razorpayOrder.currency,
        name: 'Consumer Rights Organization',
        description: 'Complaint processing fees',
        image: logoImg,
        order_id: razorpayOrder.id,
        theme: {
          color: '#3D62E8'
        },
        prefill: {
          name: user.fullName,
          email: user.email,
          contact: user.phone
        },
        handler() {
          queryClient.invalidateQueries(['complaint', params.id]);
          toast.success('The payment was successful!');
        }
      };

      const paymentPortal = new window.Razorpay(options);
      paymentPortal.open();
    } catch (err) {
      console.log(err);
      toast.error(
        err.response?.data
          ? err.response.data.message
          : 'Could not process payments at the moment'
      );
    }
  }, [params.id, user, queryClient]);

  useEffect(() => {
    (async () => {
      try {
        await loadRazorpay();
      } catch (err) {
        console.log(err);
        toast.error('Could not process payments at the moment');
      } finally {
        setIsRazorpayLoading(false);
      }
    })();
  }, []);

  if (isLoading)
    return (
      <UserLayout
        title='Complaint Details'
        mainTagClassName='h-screen flex items-center justify-center'>
        <Loader />
      </UserLayout>
    );

  return (
    <UserLayout
      title={`Complaint | ${complaint.id}`}
      mainTagClassName='overflow-y-scroll h-screen tab-port:h-auto'>
      <section className='p-10 phone:px-5'>
        <Heading variant='tertiary-left'>Complaint #{complaint.id}</Heading>

        <p className='text-xs'>
          In case of any confusion / conflicts reach out to our administrators
          using this complaint id.
        </p>

        <div className='bg-primary text-white py-6 px-8 rounded-2xl font-semibold my-12 flex items-center tab-port:p-5'>
          {statusTypes.map((status, i) => (
            <React.Fragment key={i}>
              <div
                className={classNames(
                  'h-10 w-10 rounded-full flex justify-center items-center tab-port:h-6 tab-port:w-6',
                  statusTypes.indexOf(complaint.status) >= i
                    ? 'bg-green-200'
                    : 'bg-white'
                )}>
                {icons[i]}
              </div>

              <div className='relative flex-1 text-center uppercase'>
                <p className='absolute -top-6 left-1/2 -translate-x-1/2 tab-port:text-xs tab-port:-top-5 phone:text-atmoic'>
                  {status}
                </p>
                <div
                  className={classNames(
                    'h-1 phone:h-[2px]',
                    statusTypes.indexOf(complaint.status) >= i
                      ? 'bg-green-200'
                      : 'bg-white'
                  )}></div>
              </div>
            </React.Fragment>
          ))}
          <div
            className={classNames(
              'h-10 w-10 rounded-full flex justify-center items-center tab-port:h-6 tab-port:w-6',
              statusTypes.indexOf(complaint.status) >= statusTypes.length - 1
                ? 'bg-green-200'
                : 'bg-white'
            )}>
            {icons.at(-1)}
          </div>
        </div>

        <div className='bg-white py-6 p-8 rounded-2xl flex items-center justify-between tab-port:flex-col tab-port:items-start tab-port:space-y-5 phone:p-5'>
          {(() => {
            switch (complaint.status) {
              case 'PENDING':
                return (
                  <h3>
                    Please wait until our admins see your complaint request.
                  </h3>
                );

              case 'ACCEPTED':
                return (
                  <>
                    <h3>
                      🎉Your complant has been accepted! Please make the payment
                      for further action.
                    </h3>
                    <Button
                      variant='filled'
                      isLoading={isRazorpayLoading}
                      onClick={handlePayment}>
                      Pay processing fee
                    </Button>
                  </>
                );

              case 'DECLINED':
                return (
                  <h3>
                    ❌ We are sorry, but your complaint has been rejected.
                  </h3>
                );

              case 'PAID':
                return (
                  <h3>
                    ✔️ Your have paid the processing fee. Our admins will
                    resolve your complaint as soon as possible.
                  </h3>
                );

              case 'RESOLVED':
                return (
                  <h3>
                    🥳 Congratulations! This issue has been resolved and closed.
                  </h3>
                );

              default:
                return null;
            }
          })()}
        </div>
      </section>

      <section className='border-t-2 border-gray-200 p-10 grid grid-cols-[2.5fr,1fr] gap-10 tab-port:grid-cols-none phone:px-5 phone:gap-5'>
        <div className='border-2 border-gray-200 p-5 space-y-2 rounded-md'>
          <h3 className='font-semibold text-lg capitalize text-black'>
            description
          </h3>
          <p>{complaint.issueDescription}</p>
        </div>

        <div className='border-2 border-gray-200 p-5 space-y-2 rounded-md'>
          <h3 className='font-semibold text-lg capitalize text-black'>
            opposition
          </h3>

          <ul>
            <li>
              <span className='font-semibold'>Against:</span>{' '}
              {complaint.opposition.name}
            </li>
            <li>
              <span className='font-semibold'>Person:</span>{' '}
              {complaint.opposition.personOfImportance}
            </li>
            <li>
              <span className='font-semibold'>Email:</span>{' '}
              {complaint.opposition.email}
            </li>
            <li>
              <span className='font-semibold'>Phone:</span>{' '}
              {complaint.opposition.phone}
            </li>
          </ul>
        </div>

        <div className='col-span-full border-2 border-gray-200 p-5 space-y-2 rounded-md'>
          <h3 className='font-semibold text-lg capitalize text-black'>
            Attached files
          </h3>
        </div>
      </section>
    </UserLayout>
  );
};

export default ComplaintDetailsPage;
