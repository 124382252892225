const loadRazorpay = () =>
  new Promise((resolve, reject) => {
    const script = document.createElement('script');
    script.src = 'https://checkout.razorpay.com/v1/checkout.js';

    script.onload = resolve;
    script.onerror = reject;

    document.body.appendChild(script);
  });

export default loadRazorpay;
